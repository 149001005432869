.container {
    margin: 0 auto;
    width: 60%;
    min-width: 85rem;
    max-width: 100rem;
}

.studyBorderButton:hover {
    outline: 1px solid var(--color-neutral-black);
}

@media screen and (max-width: 900px) {
    .container {
        min-width: unset;
        width: 100%;
        padding: 0 2rem;
    }
}

.flashcardCard {
    margin: 0;
    min-height: 52rem;
    height: 52rem;
    position: relative;
}

@media screen and (max-height: 768px) {
    .flashcardCard {
        height: calc(65vh - 6rem);
    }
}

@media screen and (max-width: 900px) {
    .flashcardCard {
        min-height: 60rem;
        height: calc(70vh - 6rem);
    }
}

.studyOtherModesContainer {
    position: sticky;
    bottom: 1.8rem;
    margin: 5rem auto 0 auto;
    width: 90%;
}

@media screen and (max-width: 900px) {
    .studyOtherModesContainer {
        position: relative;
        width: 100%;
        margin: 4rem auto 0 auto;
    }
}

.skeletonHeader {
    justify-content: space-between;
}

@media screen and (max-width: 600px) {
    .skeletonHeader {
        justify-content: flex-end;
    }
}

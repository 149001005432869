.offersVidContainer {
    flex: 3;
    width: 88vw;
    max-width: 88rem;
    height: 46.6rem;
}

@media screen and (max-width: 1200px) {
    .offersVidContainer {
        flex: none;
        width: 88vw;
        height: 48vw;
        max-width: 90rem;
    }
}

.tableBorder {
    border-collapse: collapse;
}

.tableBorder th,
.tableBorder tr:not(:last-child) {
    border-bottom: 1px solid var(--color-neutral-1);
}

.tableBorder td:not(:last-child),
.tableBorder th:not(:last-child) {
    border-right: 1px solid var(--color-neutral-1);
}

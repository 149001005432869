.endScreenContainer {
    padding: 6.9rem 2.5rem;
}

@media screen and (max-width: 900px) {
    .endScreenContainer {
        padding: 4.5rem 2rem;
    }
}

.adsSurveryContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-block: 1.6rem;
    width: 800px;
    max-width: 90vw;
    position: fixed;
    bottom: 20px;
    transform: translate(-50%, 0);
    left: 50%;
    border: 1px solid;
    z-index: 31;
    gap: 2.4rem;
}

.kaiImage {
    position: relative;
    bottom: -14px;
    margin: 0 4rem 0 auto;
}

@media screen and (max-width: 600px) {
    .adsSurveryContainer {
        flex-direction: column;
        padding-block: 2.4rem;
        max-width: 96vw;
    }

    .kaiImage {
        margin: 0 auto;
    }
}

.mainContainer {
    padding: 8rem 0;
    --color-accent: var(--color-accent-light);
}

@media screen and (max-width: 900px) {
    .mainContainer {
        padding: 4rem 0 8rem;
    }

    .mdDownSidePadding {
        padding-inline: 2rem 2rem !important;
    }
}

[data-theme="dark"] .mainContainer {
    --color-accent: var(--color-accent-dark);
}

.sectionTitle {
    font-size: 4.4rem;
}

@media screen and (max-width: 600px) {
    .sectionTitle {
        font-size: 1.8rem;
    }
}

.sectionSubTitle {
    max-width: 78rem;
    font-size: 2.2rem;
}

@media screen and (max-width: 600px) {
    .sectionSubTitle {
        font-size: 1.5rem;
    }
}

.videoContainerSize {
    width: 64vw;
    height: 41.3vw;
}

@media screen and (max-width: 600px) {
    .videoContainerSize {
        width: 90vw;
        height: 58.2vw;
    }
}

.gapBetweenSection {
    padding-bottom: 12rem;
}

@media screen and (max-width: 600px) {
    .gapBetweenSection {
        padding-bottom: 8rem;
    }
}

@media screen and (max-width: 900px) {
    .mdDownImg100 {
        width: 100%;
        height: 100%;
    }

    .mdDownAlignJustifyCenter {
        align-items: center !important;
        justify-content: center !important;
    }

    .videoBtnsContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .videoBtnsContainer > * {
        flex-basis: 48%;
        flex-grow: 0;
    }

    .videoBtnsContainer {
        text-align: center !important;
    }
}

@media screen and (max-width: 600px) {
    .smDownColumnStretch {
        flex-direction: column !important;
        align-items: stretch !important;
    }
}

.unidaysProMainText {
    color: var(--color-neutral-black) !important;
}

.featuresCardContainer {
    padding: 5rem 5rem 0;
    border-radius: 4rem;
}

.comingSoonLabel {
    font-size: 1.8rem;
    border-radius: 5.1rem;
}

@media screen and (max-width: 600px) {
    .comingSoonLabel {
        font-size: 1.4rem;
        border-radius: 0;
    }
}

@media screen and (max-width: 900px) {
    .featuresCardContainer {
        border-radius: 2rem;
        padding: 2.4rem 2.4rem 0;
    }
}

.bigFeatureCardTitle {
    font-size: 3.5rem;
    line-height: 4.6rem;
}

.smallFeatureCardTitle {
    font-size: 2.2rem;
    line-height: 3.2rem;
}

.featuresWithFeedbacksContainer {
    gap: 2.4rem;
}

@media screen and (max-width: 900px) {
    .featuresWithFeedbacksContainer {
        height: 100%;
        padding: 0;
    }
}

.featureCardDesc {
    font-size: 1.8rem;
    line-height: 2.8rem;
}

@media screen and (max-width: 600px) {
    .bigFeatureCardTitle {
        font-size: 1.8rem;
        line-height: 2.8rem;
    }

    .smallFeatureCardTitle {
        font-size: 1.8rem;
        line-height: 2.8rem;
    }

    .featureCardDesc {
        font-size: 1.6rem;
        line-height: 2.6rem;
    }
}

[data-theme="dark"] .unidaysProMainText {
    color: #fff !important;
}

.userBubble {
    width: 91.2rem;
    height: 11.4rem;
}

@media screen and (max-width: 900px) {
    .userBubble {
        width: 32rem;
        height: 4rem;
    }
}

.collapsedFeedbacksContainer {
    gap: 2rem;
}

@media screen and (max-width: 600px) {
    .collapsedFeedbacksContainer {
        gap: 4rem;
    }
}

@media screen and (max-width: 600px) {
    .collpasedBubblesContainer {
        justify-content: center;
    }
}

.collapsedBubble {
    margin-left: -1.2rem;
}

@media screen and (max-width: 900px) {
    .collapsedBubble {
        margin-left: -2.2rem;
    }
}

/* GRADIENT BG RELATED */
.gradientBgMainLanding {
    background: radial-gradient(61.06% 77.36% at 50% 50%, #fff 0%, #fff 42.19%, #daf4f1 99.99%);
}

[data-theme="dark"] .gradientBgMainLanding {
    background: none;
}

@media screen and (max-width: 600px) {
    .gradientBgMainLanding {
        background: radial-gradient(61.06% 77.36% at 50% 50%, #fff 0%, #fff 42.19%, #daf4f1 99.99%);
    }
}

.gradientBgOrange {
    background: radial-gradient(112.47% 9.99% at 50.97% 8.78%, #e1a03f 0%, #fff4e4 39.58%, #fff 72.92%);
}

[data-theme="dark"] .gradientBgOrange {
    background: radial-gradient(112.47% 9.99% at 50.97% 8.78%, #e1a03f 0%, #42382a 39.58%, #211f1d 72.92%);
}

@media screen and (max-width: 600px) {
    .gradientBgOrange {
        background: radial-gradient(82.65% 4.71% at 50% 5.39%, #e1a03f 0%, #fff4e4 39.06%, #fff 72.92%);
    }

    [data-theme="dark"] .gradientBgOrange {
        background: radial-gradient(82.65% 4.71% at 50% 5.39%, #e1a03f 0%, #42382a 39.06%, #211f1d 72.92%);
    }
}

.gradientBgRed {
    background: radial-gradient(74.73% 17% at 51.87% 13.86%, #bc3232 0%, #ffe0e0 40.1%, #fff 72.92%);
}

[data-theme="dark"] .gradientBgRed {
    background: radial-gradient(74.73% 17% at 51.87% 14.86%, #bc3232 0%, #4b1e1e 40.1%, #1c1717 72.92%);
}

@media screen and (max-width: 600px) {
    .gradientBgRed {
        background: radial-gradient(90.51% 6.93% at 50% 9.28%, #bc3232 0%, #ffd9d9 36.98%, #fff 72.92%);
    }

    [data-theme="dark"] .gradientBgRed {
        background: radial-gradient(90.51% 6.93% at 50% 9.28%, #bc3232 0%, #4b1e1e 36.98%, #1c1717 72.92%);
    }
}

.gradientBgPink {
    background: radial-gradient(65.97% 15.81% at 50.97% 15.55%, #c83e80 0%, #ffdaec 42.19%, #fff 72.92%);
}

[data-theme="dark"] .gradientBgPink {
    background: radial-gradient(65.97% 15.81% at 50.97% 15.55%, #c83e80 0%, #4e132f 42.19%, #241f22 72.92%);
}

@media screen and (max-width: 600px) {
    .gradientBgPink {
        background: radial-gradient(93.01% 6.89% at 50% 8.46%, #c83e80 0%, #ffe2f0 36.98%, #fff 72.92%);
    }

    [data-theme="dark"] .gradientBgPink {
        background: radial-gradient(93.01% 6.89% at 50% 8.46%, #c83e80 0%, #4e132f 36.98%, #241f22 72.92%);
    }
}

.gradientBgPurple {
    background: radial-gradient(76.32% 15.8% at 50% 17%, #823abb 0%, #f1deff 36.98%, #fff 72.92%);
}

[data-theme="dark"] .gradientBgPurple {
    background: radial-gradient(76.32% 15.8% at 50% 17%, #823abb 0%, #36184e 36.98%, #252128 72.92%);
}

@media screen and (max-width: 600px) {
    .gradientBgPurple {
        background: radial-gradient(93.79% 7.15% at 50% 12.05%, #823abb 0%, #f1deff 37.5%, #fff 72.92%);
    }

    [data-theme="dark"] .gradientBgPurple {
        background: radial-gradient(93.79% 7.15% at 50% 12.05%, #823abb 0%, #36184e 37.5%, #252128 72.92%);
    }
}

.gradientBgGreen {
    background: radial-gradient(74.93% 23% at 51.39% 23%, #50d2c2 0%, #e4fffc 38.54%, #fff 72.92%);
}

[data-theme="dark"] .gradientBgGreen {
    background: radial-gradient(74.93% 23% at 51.39% 23%, #50d2c2 0%, #154d46 38.54%, #1c2524 72.92%);
}

@media screen and (max-width: 600px) {
    .gradientBgGreen {
        background: radial-gradient(94.62% 13.43% at 50% 20.42%, #fff 0%, #fff 42.19%, #daf4f1 99.99%);
    }

    [data-theme="dark"] .gradientBgGreen {
        background: radial-gradient(94.62% 13.43% at 50% 20.42%, #50d2c2 0%, #154d46 38.02%, #1c2524 72.92%);
    }
}

.gradientBgBlue {
    background: radial-gradient(81.11% 19% at 50% 17.21%, #2875ad 0%, #e3f3ff 38.54%, #fff 72.92%);
}

[data-theme="dark"] .gradientBgBlue {
    background: radial-gradient(81.11% 18.81% at 50% 17.21%, #2875ad 0%, #153d59 38.54%, #212426 72.92%);
}

@media screen and (max-width: 600px) {
    .gradientBgBlue {
        background: radial-gradient(115.51% 8.45% at 50% 11.78%, #2875ad 0%, #e0f2ff 34.38%, #fff 72.92%);
    }

    [data-theme="dark"] .gradientBgBlue {
        background: radial-gradient(115.51% 8.45% at 50% 11.78%, #2875ad 0%, #153d59 34.38%, #212426 72.92%);
    }
}

.gradientChromeHelp {
    background: radial-gradient(40.05% 40.05% at 50% 59.95%, #629dc8 0%, #c6e0f3 44.27%, #fff 100%);
}

[data-theme="dark"] .gradientChromeHelp {
    background: radial-gradient(40.05% 40.05% at 50% 59.95%, #629dc8 0%, #1c3a4e 44.27%, #212426 100%);
}

.gradientBgUnidaysLimitless {
    background: radial-gradient(61.06% 77.36% at 50% 50%, #fff 0%, #fff 42.19%, #daf4f1 99.99%),
        linear-gradient(0deg, var(--neutrals-background, #f4f4f4) 0%, var(--neutrals-background, #f4f4f4) 100%),
        linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
}

[data-theme="dark"] .gradientBgUnidaysLimitless {
    background: radial-gradient(61.06% 77.36% at 50% 50%, #fff 0%, #fff 42.19%, #daf4f1 99.99%),
        linear-gradient(0deg, var(--neutrals-background, #f4f4f4) 0%, var(--neutrals-background, #f4f4f4) 100%),
        linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
}

.capsulesContainer {
    font-size: 1.5rem;
    max-width: 65%;
}

@media screen and (max-width: 1600px) {
    .capsulesContainer {
        max-width: 90%;
    }
}

@media screen and (max-width: 600px) {
    .capsulesContainer {
        font-size: 1.2rem;
        max-width: 80%;
    }
}

.badgesContainer {
    display: grid;
    padding-right: 1rem; /* for the unclaimed badge indicator to be visible */
    grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
}

@media screen and (max-width: 900px) {
    .badgesContainer {
        grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
    }
}

@media screen and (max-width: 700px) {
    .badgesContainer {
        grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
    }
}

@media screen and (max-width: 450px) {
    .badgesContainer {
        grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    }
}

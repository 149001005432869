.container {
    min-height: calc(99vh - var(--navbar-height));
    position: relative;
    padding: 3.6rem 3.6rem 7.2rem 3.6rem;
    flex: 1;
}

@media screen and (max-width: 900px) {
    .container {
        padding: 3rem 5rem 7.2rem 5rem;
    }
}

@media screen and (max-width: 600px) {
    .container {
        padding: 2rem 2rem 5rem 2rem;
    }
}

.blogCard {
    max-height: 52rem;
}

.blogCard:hover .title {
    color: #50d2c2;
    transition: 0.15s;
}

.blogGrid {
    grid-template-columns: repeat(2, minmax(27rem, 1fr));
}

@media screen and (max-width: 700px) {
    .blogGrid {
        grid-template-columns: repeat(1, minmax(27rem, 1fr));
    }
}

.sideBarTag {
    padding: 0 0.8rem;
    border-radius: 7px;
    color: var(--color-neutral-black);
    height: 4rem;
    cursor: pointer;
}

.sideBarTag:hover {
    background-color: #f7f7f7;
}

[data-theme="dark"] .sideBarTag:hover {
    background-color: #1d1e1e;
}

.sideBarTagSelected {
    background-color: var(--color-neutral-1);
}

.link {
    --link-color: var(--color-neutral-black);
    color: var(--link-color);
    text-decoration: none;
}

.link:hover {
    --link-hover-color: #50d2c2;
    color: var(--link-hover-color);
    transition: 0.08s;
}

.profileHeader {
    --color-profile-dynamic-bg-color: var(--color-card-primary);
    --color-profile-dynamic-text-color: #000000;
    color: var(--color-profile-dynamic-text-color);
    background-color: var(--color-profile-dynamic-bg-color);
}

[data-theme="dark"] .profileHeader {
    --color-profile-dynamic-text-color: #ffffff;
}

.coverImage {
    height: 24rem;
}

@media screen and (max-width: 600px) {
    .profileHeader {
        height: 50rem;
    }

    .coverImage {
        height: 80%;
    }
}

.userAvatarSizeAndPosAdj {
    margin-top: -10rem;
}

@media screen and (max-width: 1270px) {
    .brProfileXlColumn {
        flex-direction: column !important;
    }

    .brProfileXlPositionStatic {
        position: static !important;
    }
}

@media screen and (max-width: 900px) {
    .userAvatarSizeAndPosAdj {
        margin-top: -8rem;
    }
}

@media screen and (max-width: 600px) {
    .smDownMargin0 {
        margin: 0 !important;
    }

    .smDownPadding0 {
        padding: 0 !important;
    }

    .smDownBorderRadius0 {
        border-radius: 0 !important;
    }
}

.container {
    min-height: calc(99vh - var(--navbar-height));
    position: relative;
    padding: 0 3.6rem;
    flex: 1;
}

@media screen and (max-width: 900px) {
    .container {
        padding: 0 5rem;
    }
}

@media screen and (max-width: 600px) {
    .container {
        padding: 0;
    }
}

.itemContainer {
    padding: 0;
}

@media screen and (max-width: 900px) {
    .itemContainer {
        padding: 2rem 2rem;
    }
}
